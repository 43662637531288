<template>
  <b-form class="content-form">
    <!-- Title / Description -->
    <b-tabs content-class="mt-3" nav-class="no-padding">
      <b-tab v-for="(language, index) in languages" :key="index">
        <template #title>
          <country-flag :country="isoLanguage(language)" size="normal" />
        </template>
        <b-form-group label="Title">
          <b-form-input v-model="content.intro.title[language]"></b-form-input>
        </b-form-group>
        <b-form-group label="Description">
          <b-alert show small
            >Use {{ "\{\{ firstName \}\}" }}, to show current user's firstname
            in text!</b-alert
          >
          <quill-editor
            :content="content.intro.message[language]"
            v-model="content.intro.message[language]"
            :options="editorOption"
          />
        </b-form-group>
      </b-tab>
    </b-tabs>
  </b-form>
</template>
<script>
export default {
  props: ["content"],
  data() {
    return {
      editorOption: {},
    };
  },
  computed: {
    languages() {
      return this.$store.getters["Languages/all"];
    },
  },
  methods: {
    isoLanguage(language) {
      let iso = this.$i18n.t(`languages.${language}`).toLowerCase();
      if (iso == "en") {
        iso = "us";
      }
      return iso;
    },
  },
};
</script>

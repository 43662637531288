<template>
  <b-modal
    v-model="value"
    title="Add/Edit Slide"
    ref="slide-modal"
    id="slide-modal"
  >
    <b-form @submit.prevent="save">
      <b-tabs content-class="mt-3" nav-class="no-padding">
        <b-tab v-for="(language, index) in languages" :key="index">
          <template #title>
            <country-flag :country="isoLanguage(language)" size="normal" />
          </template>
          <b-form-group label="Title">
            <b-form-input
              name="title"
              v-validate="'required|min:3|max:50'"
              v-model="selectedSlide.title[language]"
            ></b-form-input>
            <div v-if="submitted && errors.has('title')" class="alert-danger">
              {{ errors.first("title") }}
            </div>
          </b-form-group>
          <b-form-group label="Description">
            <b-form-textarea
              name="Description"
              v-validate="'required|min:3|max:50'"
              v-model="selectedSlide.description[language]"
            ></b-form-textarea>
            <div v-if="submitted && errors.has('description')" class="alert-danger">
              {{ errors.first("description") }}
            </div>
          </b-form-group>
          <b-form-group label="Button text">
            <b-form-input
              name="button-text"
              v-validate="'required|min:3|max:50'"
              v-model="selectedSlide.button_text[language]"
            ></b-form-input>
            <div v-if="submitted && errors.has('button_text')" class="alert-danger">
              {{ errors.first("button_text") }}
            </div>
          </b-form-group>
        </b-tab>
      </b-tabs>
      <b-form-group label="Image">
        <b-form-file name="image" :placeholder="selectedSlide.image" accept="image/jpeg, image/png, image/gif" v-validate="'required'" v-model="selectedSlide.image" />
        <div v-if="submitted && errors.has('image')" class="alert-danger">
          {{ errors.first("image") }}
        </div>
      </b-form-group>
    </b-form>
    <template #modal-footer>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <b-button type="submit" @click="save()">Save</b-button>
      <b-button @click="value = false">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios"
export default {
  props: [
    "value",
    "content",
    "settings",
    "showSlideModal",
    "selectedSlide",
    "blockIndex",
    "itemIndex",
  ],
  components: {},
  data() {
    return {
      submitted: false,
      successful: false,
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  computed: {
    languages() {
      return this.$store.state.Languages.all.setting_value;
    },
  },
  methods: {
    isoLanguage(language) {
      let iso = this.$i18n.t(`languages.${language}`).toLowerCase();
      if (iso == "en") {
        iso = "us";
      }
      return iso;
    },
    uploadFile(file) {
      let formData = new FormData();
      formData.append('file', file);
      const response = axios.post(`${process.env.VUE_APP_API_URL}/files/upload`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Origin": "*",
        },
        responseType: 'json',
      })
      console.log(response.data)
    },
    save() {
      this.message = "";
      this.submitted = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        if (this.blockIndex !== null && this.itemIndex !== null) {
          console.log(this.selectedSlide.image)
          this.uploadFile(this.selectedSlide.image) 
          this.content.blocks[this.blockIndex].slides[this.itemIndex] = this.selectedSlide;
          this.value = false;
        } else {
          this.content.blocks[this.blockIndex].slides.push(this.selectedSlide);
          this.value = false;
        }
      });
    },
  },
};
</script>

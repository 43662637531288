<template>
  <b-form class="content-form">
    <b-form-checkbox v-model="content.interaction.active" name="active" class="content-switch" switch><b>Active</b></b-form-checkbox>
    <!-- Type / Identifier -->
    <b-form-group label="Type">
      <b-form-select v-model="content.interaction.type" :options="interactionTypes"></b-form-select>
    </b-form-group>
    <b-form-group label="Embed-code" v-if="content.interaction.type !== 'online-users'">
      <b-form-input v-model="content.interaction.id"></b-form-input>
    </b-form-group>
  </b-form>
</template>
<script>
export default {
  props: ["content", "settings"],
  data() {
    return {
      interactionTypes: [
        "slido",
        "vimeo",
        "online-users"
      ],
    }
  },
}
</script>

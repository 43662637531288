<template>
  <div>
    <!-- DELETE MODAL -->
    <b-modal
      header-bg-variant="danger"
      header-text-variant="light"
      id="delete-modal"
      title="Delete?"
    >
      <div class="d-block text-center">
        <h1>Are you sure you want to delete this block?</h1>
      </div>
      <template #modal-footer>
        <b-button variant="success" @click="removeAt(blockIndex)">Ok</b-button>
        <b-button @click="$bvModal.hide('delete-modal')">Cancel</b-button>
      </template>
    </b-modal>
    <button class="btn btn-secondary button" @click="add">Add</button>
    <draggable
      class="list-group"
      tag="ul"
      :list="content.blocks"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <b-card
          class="list-group-item"
          v-for="(block, index) in content.blocks"
          :key="index"
          no-body
        >
          <!-- HEADER -->
          <b-card-header
            v-b-toggle="`accordion-${index}`"
            header-tag="header"
            class="block-card-header"
            role="tab"
          >
            <b-form-checkbox
              v-model="block.active"
              name="active"
              switch
            ></b-form-checkbox>
            <span class="text">{{ translate(block.title) }} </span>
            <b-badge class="overlay-category">{{ block.type }}</b-badge>
            <font-awesome-icon
              class="trash ml-3"
              icon="trash"
              @click="showDeleteModal(index)"
              ref="btnShowDelete"
            />
            <div class="header-items-right">
              <font-awesome-icon
                class="handle drag-icon"
                icon="grip-vertical"
              />
            </div>
          </b-card-header>
          <!-- COLLAPSE BODY -->
          <b-collapse
            :id="`accordion-${index}`"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <!-- TYPE  -->
              <b-form-group label="Type">
                <b-form-select
                  v-model="block.type"
                  :options="blockTypes"
                ></b-form-select>
              </b-form-group>
              <!-- TITLE  -->
              <b-tabs content-class="mt-3" nav-class="no-padding">
                <b-tab v-for="(language, index) in languages" :key="index">
                  <template #title>
                    <country-flag
                      :country="isoLanguage(language)"
                      size="normal"
                    />
                  </template>
                  <b-form-group label="Title">
                    <b-form-input
                      v-model="block.title[language]"
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
              </b-tabs>
              <!-- SLIDES -->
                <div v-if="block.type == 'carousel'">
                  <h1>Slides</h1>
                  <b-button
                    @click="addSlide(index)"
                    class="mb-3"
                    >Add slide</b-button
                  >
                    <vuetable
                      ref="cardtable"
                      :api-mode="false"
                      :fields="[
                        'title.nl_NL',
                        'description.nl_NL',
                        'button_text.nl_NL',
                        'image',
                        'actions'
                      ]"
                      :data="block.slides"
                    >
                      <!-- <draggable
                        class="list-group"
                        tag="ul"
                        v-model="block.slides"
                        :list="block.slides"
                        v-bind="dragSlideOptions"
                        @start="dragSlide = true"
                        @end="dragSlide = false"
                      > -->
                      <!-- <transition-group type="transition" :name="!dragSlide ? 'flip-list' : null"> -->
                      <template slot="actions" scope="props">
                        <font-awesome-icon
                          icon="edit"
                          data-toggle="tooltip" 
                          data-placement="top" 
                          title="Edit slide"
                          @click="selectSlide(index, props.rowIndex)"
                          class="action-icon"
                          ref="btnEdit"
                          ></font-awesome-icon>
                        <font-awesome-icon
                          icon="trash"
                          data-toggle="tooltip" 
                          data-placement="top" 
                          title="Remove slide"
                          class="ml-2 action-icon"
                          @click="removeNesteSlidedAt(index, props.rowIndex)"
                        ></font-awesome-icon>
                      </template>
                      <!-- </transition-group> -->
                      <!-- </draggable> -->
                    </vuetable>
              </div>
              <!-- SLIDES -->
              <div v-if="block.type == 'items'">
                <h1>Items</h1>
                <b-button
                  @click="addTableItem(index)"
                  class="mb-3"
                  >Add item</b-button
                >
                <vuetable
                  ref="cardtable"
                  :api-mode="false"
                  :fields="[
                    'type',
                    'category.nl_NL',
                    'title.nl_NL',
                    'image',
                    'url',
                    'date',
                    'actions',
                  ]"
                  :data="block.blocks"
                >
                  <template slot="actions" scope="props">
                    <font-awesome-icon
                      icon="edit"
                      class="action-icon"
                      data-toggle="tooltip" 
                      data-placement="top" 
                      title="Edit item"
                      @click="selectTableItem(index, props.rowIndex)"
                      ref="btnEdit"
                      ></font-awesome-icon>
                    <font-awesome-icon
                      icon="trash"
                      class="ml-2 action-icon"
                      data-toggle="tooltip" 
                      data-placement="top" 
                      title="Remove item"
                      @click="removeNestedAt(index, props.rowIndex)"
                      ></font-awesome-icon>
                  </template>
                </vuetable>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </transition-group>
    </draggable>
    <CardAddEditModal
      :settings="settings"
      :content="content"
      v-model="showModal"
      :blockIndex="blockIndex"
      :itemIndex="itemIndex"
      :selectedItem="selectedItem"
    />
    <SlideAddEditModal
      :settings="settings"
      :content="content"
      v-model="showSlideModal"
      :blockIndex="blockIndex"
      :itemIndex="itemIndex"
      :selectedSlide="selectedSlide"
    />
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { translateSetting } from "@/utils/translated-setting.util";
import Vuetable from "vuetable-2";
import CardAddEditModal from "@/components/admin/home/CardAddEditModal.vue";
import SlideAddEditModal from "@/components/admin/home/SlideAddEditModal.vue";

export default {
  props: ["content", "settings"],
  components: {
    Vuetable,
    draggable,
    CardAddEditModal,
    SlideAddEditModal
  },
  data() {
    return {
      blockTypes: ["carousel", "banner", "items"],
      drag: false,
      selectedItem: "",
      selectedSlide: "",
      blockIndex: null,
      itemIndex: null,
      showModal: false,
      showSlideModal: false
    };
  },
  methods: {
    translate(value) {
      return translateSetting(value, this.locale);
    },
    showDeleteModal(index) {
      this.$root.$emit("bv::show::modal", "delete-modal", "#btnShowDelete");
      this.blockIndex = index;
    },
    removeAt(index) {
      this.$root.$emit("bv::hide::modal", "delete-modal", "#btnShowDelete");
      this.content.blocks.splice(index, 1);
    },
    removeNestedAt(index, itemIndex) {
      this.content.blocks[index].blocks.splice(itemIndex, 1);
    },
    removeNestedSlideAt(index, itemIndex) {
      this.content.blocks[index].slides.splice(itemIndex, 1);
    },
    add: function() {
      this.content.blocks.push({ title: "new block", description: "" });
    },
    addTableItem(index) {
      this.blockIndex = index;
      this.itemIndex = null;
      this.selectedItem = {
        type: "",
        title: {
          en_US: "",
          nl_NL: "",
        },
        category: {
          en_US: "",
          nl_NL: "",
        },
        description: {
          en_US: "",
          nl_NL: "",
        },
        image: "",
        url: "",
        date: "",
        openBlank: true,
      };
      this.toggleModal();
    },
    addSlide(index) {
      this.blockIndex = index;
      this.itemIndex = null;
      this.selectedSlide = {
        title: {
          en_US: "",
          nl_NL: "",
        },
        description: {
          en_US: "",
          nl_NL: "",
        },
        button_text: {
          en_US: "",
          nl_NL: "",
        },
        image: ""
      };
      this.toggleSlideModal();
    },
    selectTableItem(blockIndex, itemIndex) {
      this.blockIndex = blockIndex;
      this.itemIndex = itemIndex;
      this.selectedItem = this.content.blocks[this.blockIndex].blocks[this.itemIndex];
      this.toggleModal();
    },
    selectSlide(blockIndex, itemIndex) {
      this.blockIndex = blockIndex;
      this.itemIndex = itemIndex;
      this.selectedSlide = this.content.blocks[this.blockIndex].slides[this.itemIndex];
      this.toggleSlideModal();
    },
    isoLanguage(language) {
      let iso = this.$i18n.t(`languages.${language}`).toLowerCase();
      if (iso == "en") {
        iso = "us";
      }
      return iso;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleSlideModal() {
      this.showSlideModal = !this.showSlideModal;
    },
  },
  computed: {
    locale() {
      const language = localStorage.getItem("locale");
      return this.$i18n.t(`languages.${language}`).toLowerCase();
    },
    languages() {
      return this.settings.languages;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    dragSlideOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);


.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}

.block-card-header {
  display: flex;
  align-items: center;
}

.block-action-buttons {
  float: right;
  margin-bottom: 20px;
}

.block-action-buttons > * {
  margin-left: 20px;
}

.header-items-right {
  margin-left: auto;
}

.header-items-right > * {
  margin-left: 10px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.drag-icon {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
.button {
  margin-top: 35px;
}
.handle {
  float: left;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}
</style>

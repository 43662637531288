<template>
  <div>
    <h1>Home</h1>
    <b-alert variant="warning" :show="saveMessage !== ''">{{
      this.saveMessage
    }}</b-alert>
    <b-tabs>
      <b-tab title="Intro">
        <IntroConfig :content="content" :settings="settings" />
      </b-tab>

      <b-tab title="Content">
        <ContentConfig :content="content" :settings="settings" />
      </b-tab>

      <b-tab title="Interaction">
        <InteractionConfig :content="content" :settings="settings" />
      </b-tab>
    </b-tabs>
    <b-button
      class="mb-5 save-button"
      @click="saveAll()"
      :disabled="saveDisabled"
      >Save All</b-button
    >
  </div>
</template>
<script>
import IntroConfig from "@/components/admin/home/IntroConfig.vue";
import ContentConfig from "@/components/admin/home/ContentConfig.vue";
import InteractionConfig from "@/components/admin/home/InteractionConfig.vue";

export default {
  components: {
    IntroConfig,
    ContentConfig,
    InteractionConfig,
  },
  data() {
    return {
      active: true,
      newContent: "",
      oldContent: "",
      saveDisabled: true,
      saveMessage: "",
    };
  },
  created() {
    this.saveDisabled = true;
    this.saveMessage = "";
    this.$store.dispatch("Dash/getDashboardContent");
  },
  watch: {
    content: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    content() {
      return this.$store.state.Dash.content;
    },
    settings() {
      return this.$store.state.Config.setting;
    },
  },
  methods: {
    async saveAll() {
      await this.$store.dispatch("Dash/updateDashboardContent", this.content);
      // await this.$store.dispatch("Config/updateSetting", this.settings);
      this.$root.$bvToast.toast("Home settings saved.", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.language-tab {
  ul {
    color: $color-primary;
  }
}

.save-button {
  margin-top: 15px;
}
</style>
<style lang="scss">
.language-tab {
  .nav-link {
    padding: 2px 2px !important;
  }
}

.no-padding {
  a {
    padding: 0px !important;
  }
}

.content-form {
  padding-top: 15px;
}

.content-switch {
  margin-bottom: 15px;
}

.component-switch {
  margin-left: 15px;
}
</style>

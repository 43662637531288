<template>
  <b-modal
    v-model="value"
    title="Add/Edit Item"
    ref="card-modal"
    id="card-modal"
  >
    <b-form @submit.prevent="save">
      <b-form-group label="Type">
        <b-select
          name="type"
          v-validate="'required'"
          :options="itemTypes"
          v-model="selectedItem.type"
        ></b-select>
        <div v-if="submitted && errors.has('type')" class="alert-danger">
          {{ errors.first("type") }}
        </div>
      </b-form-group>
      <b-tabs content-class="mt-3" nav-class="no-padding">
        <b-tab v-for="(language, index) in languages" :key="index">
          <template #title>
            <country-flag :country="isoLanguage(language)" size="normal" />
          </template>
          <b-form-group label="Title">
            <b-form-input
              name="title"
              v-validate="'required|min:3|max:50'"
              v-model="selectedItem.title[language]"
            ></b-form-input>
            <div v-if="submitted && errors.has('title')" class="alert-danger">
              {{ errors.first("title") }}
            </div>
          </b-form-group>
          <b-form-group label="Category">
            <b-form-input
              name="category"
              v-validate="'required|min:3|max:50'"
              v-model="selectedItem.category[language]"
            ></b-form-input>
          </b-form-group>
          <div v-if="submitted && errors.has('category')" class="alert-danger">
            {{ errors.first("category") }}
          </div>
          <b-form-group label="Description">
            <b-form-textarea
              name="description"
              v-model="selectedItem.description[language]"
            ></b-form-textarea>
          </b-form-group>
        </b-tab>
      </b-tabs>
      <b-form-group label="Image">
        <b-form-file name="image"  :placeholder="selectedItem.image" accept="image/jpeg, image/png, image/gif" v-model="selectedItem.image" />
      </b-form-group>
      <b-form-group label="Url">
        <b-form-input v-model="selectedItem.url" />
      </b-form-group>
      <b-form-group label="Start date">
        <b-form-datepicker v-model="selectedItem.date"></b-form-datepicker>
      </b-form-group>
    </b-form>
    <template #modal-footer>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <b-button type="submit" @click="save()">Save</b-button>
      <b-button @click="value = false">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios"

export default {
  props: [
    "value",
    "content",
    "selectedItem",
    "blockIndex",
    "itemIndex",
  ],
  components: {},
  data() {
    return {
      submitted: false,
      successful: false,
      itemTypes: ["card"],
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  computed: {
    languages() {
      return this.$store.state.Languages.all;
    },
  },
  methods: {
    isoLanguage(language) {
      let iso = this.$i18n.t(`languages.${language}`).toLowerCase();
      if (iso == "en") {
        iso = "us";
      }
      return iso;
    },
    uploadFile(file) {
      let formData = new FormData();
      formData.append('file', file);
      const response = axios.post(`${process.env.VUE_APP_API_URL}/files/upload`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Origin": "*",
        },
        responseType: 'json',
      })
      console.log(response.data)
    },
    save() {
      this.message = "";
      this.submitted = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        if (this.blockIndex !== null && this.itemIndex !== null) {
          this.content.blocks[this.blockIndex].blocks[
            this.itemIndex
          ] = this.selectedItem;
          this.value = false;
        } else {
          this.content.blocks[this.blockIndex].blocks.push(this.selectedItem);
          this.value = false;
        }
      });
    },
  },
};
</script>
